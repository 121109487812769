import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import __c from "../utilities/combineClasses"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProfitCalculator from "../components/profit-calculator"

import style from "./agencies.module.scss"

export default () => {
  const data = useStaticQuery(graphql`
    {
      reporting: file(name: { eq: "agency--automated-reporting" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            maxWidth: 584
            layout: FLUID
            quality: 100
            background: "#46128a"
            placeholder: DOMINANT_COLOR
          )
        }
      }
      annotations: file(name: { eq: "agency--annotations" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            maxWidth: 584
            layout: FLUID
            quality: 100
            background: "#46128a"
            placeholder: DOMINANT_COLOR
          )
        }
      }
      dashboards: file(name: { eq: "agency--dashboards" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            maxWidth: 584
            layout: FLUID
            quality: 100
            background: "#46128a"
            placeholder: DOMINANT_COLOR
          )
        }
      }
      set: file(name: { eq: "agency--easy-setup" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            maxWidth: 584
            layout: FLUID
            quality: 100
            background: "#46128a"
            placeholder: DOMINANT_COLOR
          )
        }
      }
      ownership: file(name: { eq: "agency--ownership" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            maxWidth: 584
            layout: FLUID
            quality: 100
            background: "#46128a"
            placeholder: DOMINANT_COLOR
          )
        }
      }
    }
  `)
  return (
    <Layout className={__c(style.gridLayout)}>
      <SEO title="Agency solutions" />
      <article className={__c(style.article)}>
        <header className={__c(style.header)}>
          <h1 className={__c(style.pageTitle)}>Agency solutions</h1>
        </header>
        <main className={__c(style.main)}>
          <section className={__c(style.introduction)}>
            <p>
              VariaMetrix is an agency-friendly tool that streamlines your work
              with clients and showcases the effectiveness of everything you do
              for them. Write executive summaries directly with the data and
              filter it to demonstrate your value proposition. Save time by
              automating the build and delivery of reports and dashboards. As an
              agency partner, you&apos;ll have full access to this powerful tool
              and the ability to resell it to your clients at your rate,
              white-labelled for your brand.
            </p>
          </section>
          <section className={__c(style.functions)}>
            <ul className={__c(style.functList)}>
              <li
                className={__c([
                  style.functListItem,
                  style.functListItemFunction,
                  `branded-presentations`,
                ])}
              >
                Branded presentations
              </li>
              <li
                className={__c([
                  style.functListItem,
                  style.functListItemFunction,
                  `shared-insights`,
                ])}
              >
                Shared insights
              </li>
              <li
                className={__c([
                  style.functListItem,
                  style.functListItemFunction,
                  `private-workspaces`,
                ])}
              >
                Private workspaces
              </li>
              <li
                className={__c([
                  style.functListItem,
                  style.functListItemFunction,
                  `extra-revenue`,
                ])}
              >
                Extra revenue
              </li>
              <li
                className={__c([
                  style.functListItem,
                  style.functListItemFunction,
                  `easy-setup`,
                ])}
              >
                Easy set-up
              </li>
              <li
                className={__c([
                  style.functListItem,
                  style.functListItemFunction,
                  `total-data-ownership`,
                ])}
              >
                Total data ownership
              </li>
            </ul>
          </section>

          <section className={__c([style.benefit, `b-a`])}>
            <h3 className={__c(style.benefitHeading)}>
              Automated, presentation-ready reporting
            </h3>
            <p className={__c(style.benefitBlurb)}>
              Export any dashboard directly to PowerPoint. Every chart block is
              a slide that uses native Office data. That means you can apply
              your agency templates with one click—fully white-labelled.
            </p>
            <GatsbyImage
              className={__c(style.benefitImage)}
              alt="PowerPoint showing live data exported from VariaMetrix"
              image={data.reporting.childImageSharp.gatsbyImageData}
            />
          </section>

          <section className={__c([style.benefit, `b-b`])}>
            <h3 className={__c(style.benefitHeading)}>
              Insights discovered by one are visible to all
            </h3>
            <p className={__c(style.benefitBlurb)}>
              VariaMetrix annotations are attached to the data, not the chart.
              When an analyst adds notes to a report, all other reports that
              pull the same data get to see it. This builds a central knowledge
              base, preventing communication mishaps.
            </p>
            <GatsbyImage
              className={__c(style.benefitImage)}
              alt="VariaMetrix annotations screenshot"
              image={data.annotations.childImageSharp.gatsbyImageData}
            />
          </section>

          <section className={__c([style.benefit, `b-c`])}>
            <h3 className={__c(style.benefitHeading)}>
              Separate dashboards for clients and management
            </h3>
            <p className={__c(style.benefitBlurb)}>
              It&apos;s important to keep works-in-progress from cluttering up
              clients&apos; attention&mdash;or worse, presenting incomplete or
              misleading information. VariaMetrix allows you to publish
              dashboards to clients when you&apos;re confident with them and on
              your terms.
            </p>
            <GatsbyImage
              className={__c(style.benefitImage)}
              alt="VariaMetrix dashboard management screenshot"
              image={data.dashboards.childImageSharp.gatsbyImageData}
            />
          </section>

          <section className={__c([style.benefit, `b-d`])}>
            <h3 className={__c(style.benefitHeading)}>
              Easy set-up without developer support
            </h3>
            <p className={__c(style.benefitBlurb)}>
              Ease-of-use is one of the core goals of VariaMetrix. The faster
              you get set up and the easier you can create reports, the sooner
              you can start providing top-tier value to your clients. Nearly
              everything is drag-and-drop or uses simple configuration panes,
              with the minimal code-fussing.
            </p>
            <GatsbyImage
              className={__c(style.benefitImage)}
              alt="VariaMetrix configuration screenshot"
              image={data.set.childImageSharp.gatsbyImageData}
            />
          </section>

          <section className={__c([style.benefit, `b-e`])}>
            <h3 className={__c(style.benefitHeading)}>You own the data</h3>
            <p className={__c(style.benefitBlurb)}>
              &ldquo;Free&rdquo; third-party analytics services collect data on
              your behalf, use it for their own purposes, and let you have some
              of it back. VariaMetrix gives you complete access to all of the
              data it collects, and always makes it available for export or
              reporting or anything else you want to do with it.
            </p>
            <GatsbyImage
              className={__c(style.benefitImage)}
              alt="Padlock near a computer"
              image={data.ownership.childImageSharp.gatsbyImageData}
            />
          </section>
        </main>
        <ProfitCalculator className={__c(style.profitCalculator)}>
          <p>
            With a pricing model that encourages reselling and features that
            shave time off your analytics workflow, it just makes financial
            sense to pair VariaMetrix with the professional services you already
            offer.
          </p>
          <p>Use the sliders to calculate your potential profit.</p>
        </ProfitCalculator>
        <footer></footer>
      </article>
    </Layout>
  )
}
