import React from "react"
import PropTypes from "prop-types"
import __c from "../utilities/combineClasses"

import style from "./profit-calculator.module.scss"

const numberFormat = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
  maximumSignificantDigits: 3,
})

export default class ProfitCalculator extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }
  agencyFee = 1000
  userFee = 100

  constructor(props) {
    super(props)
    this.state = {
      clients: 10,
      charge: 250,
      profit: "£500",
    }
  }

  updateOnChange = e => {
    e.preventDefault()
    let updateArray = []
    updateArray[e.target.dataset.updatevalue] = e.target.value
    this.setState(updateArray, this.calculateProfit)
  }

  calculateProfit = () => {
    let profit =
      this.state.clients * this.state.charge -
      (this.agencyFee + this.userFee * this.state.clients)
    this.setState({
      profit: numberFormat.format(profit),
    })
  }

  render = () => {
    const { children, className } = this.props
    return (
      <aside className={__c([style.component, className])} data-theme="callout">
        <header className={__c(style.header)}>
          <h1>Hard numbers</h1>
          <div>{children}</div>
        </header>
        <main className={__c(style.sliderContainer)} data-theme="extra-callout">
          <div className={__c([`fieldset`, `range`])}>
            <label
              className={__c(style.fieldLabel)}
              htmlFor="profitCalculatorClients"
            >
              Number of clients
            </label>
            <div className={__c([`rangeComboSlider`, style.singleSlide])}>
              <input
                tabIndex={-1}
                type="range"
                min="1"
                max="500"
                value={this.state.clients}
                data-updatevalue="clients"
                onChange={this.updateOnChange}
              />
              <span className={__c(style.currencySymbol)}></span>
              <input
                id="profitCalculatorClients"
                type="number"
                value={this.state.clients}
                data-updatevalue="clients"
                onChange={this.updateOnChange}
              />
            </div>
          </div>
          <div className={__c([`fieldset`, `range`])}>
            <label
              className={__c(style.fieldLabel)}
              htmlFor="profitCalculatorCharge"
            >
              Amount you'll charge each client monthly
            </label>
            <div className={__c([`rangeComboSlider`, style.singleSlide])}>
              <input
                tabIndex={-1}
                type="range"
                min="100"
                max="1000"
                value={this.state.charge}
                data-updatevalue="charge"
                onChange={this.updateOnChange}
              />
              <span className={__c(style.currencySymbol)}>£</span>
              <input
                id="profitCalculatorCharge"
                type="number"
                value={this.state.charge}
                data-updatevalue="charge"
                onChange={this.updateOnChange}
              />
            </div>
            <p className={__c(style.suggestion)}>Suggested: £250/month</p>
          </div>
        </main>
        <footer className={__c(style.display)}>
          <p className={__c(style.displayHead)}>Your profit</p>
          <p className={__c(style.displayProfit)}>{this.state.profit}</p>
          <p className={__c(style.displayFoot)}>per month</p>
        </footer>
      </aside>
    )
  }
}
